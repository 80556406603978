import React, { useEffect } from "react"

import "./styles.css"
import { callAnalyticsEvent } from "../utils"
import { NavbarLayout } from "../layouts"

const WrappedApp = () => {
  return (
    <NavbarLayout agentId="66c249ee3ac0d74dab597b10" persistOpenChat fullScreen>
      <App />
    </NavbarLayout>
  )
}

const App = () => {
  useEffect(() => {
    callAnalyticsEvent("view_privacy_page")
  }, [])

  return <div></div>
}

export default WrappedApp
